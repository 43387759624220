import React, { useState, useEffect, useContext } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Select from 'react-select';  // Import react-select
import { AuthContext } from '../App'; // Assuming AuthContext is in App.js
import './Dashboard.css';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Dashboard = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [userName, setUserName] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const [images, setImages] = useState([]);
  const [taskType, setTaskType] = useState('Object Detection'); // Static task type for now
  const [includeClasses, setIncludeClasses] = useState([]);  // Classes to include
  const [excludeClasses, setExcludeClasses] = useState([]);  // Classes to exclude
  const [minWidth, setMinWidth] = useState(128);
  const [minHeight, setMinHeight] = useState(128);
  const [labelQuality, setLabelQuality] = useState(50);
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [customerId, setCustomerId] = useState(null); // Add state for customer ID
  const [imageDimensions, setImageDimensions] = useState({});

const classColors = {
  aeroplane: 'red',
  bicycle: 'blue',
  bird: 'green',
  boat: 'purple',
  bottle: 'orange',
  bus: 'teal',
  car: 'pink',
  cat: 'yellow',
  chair: 'brown',
  cow: 'cyan',
  diningtable: 'magenta',
  dog: 'lime',
  horse: 'maroon',
  motorbike: 'navy',
  person: 'gold',
  pottedplant: 'olive',
  sheep: 'coral',
  sofa: 'violet',
  train: 'silver',
  tvmonitor: 'turquoise',
};



const classnames = [
  'aeroplane',
  'bicycle',
  'bird',
  'boat',
  'bottle',
  'bus',
  'car',
  'cat',
  'chair',
  'cow',
  'diningtable',
  'dog',
  'horse',
  'motorbike',
  'person',
  'pottedplant',
  'sheep',
  'sofa',
  'train',
  'tvmonitor'
];

  // Fetch Stripe Customer ID from the backend
  const fetchCustomerId = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/get-customer-id`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setCustomerId(data.customer_id);  // Set the customer ID from the backend
    } catch (error) {
      console.error('Error fetching customer ID:', error);
    }
  };

  // Handle creation of the Stripe Customer Portal session
  const handleCreatePortalSession = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ customer_id: customerId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      window.location.href = data.url;  // Redirect to Stripe's Customer Portal
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  };

  // Add "Include All" and "Exclude All" options to the class options list
  const classOptions = [
    { value: 'all', label: 'Include All Classes' },  // Add this for 'Include All'
    ...classnames.map(name => ({ value: name, label: name })),
  ];

  // Handle selection for including classes
  const handleIncludeChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      // If "Include All Classes" is selected, set all classes
      setIncludeClasses(classnames);
    } else {
      // Otherwise, map the selected options normally
      setIncludeClasses(selectedOptions ? selectedOptions.map(option => option.value) : []);
    }
  };
  const handleExcludeChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      // If "Include All Classes" is selected, set all classes
      setExcludeClasses(classnames);
    } else {
      // Otherwise, map the selected options normally
      setExcludeClasses(selectedOptions ? selectedOptions.map(option => option.value) : []);
    }
  };

  // Fetch data with dynamic query
const fetchData = async (page = 1, fetchAll = false) => {
  setLoading(true);
  setSearchInitiated(true);

  const q = {
    include_classes: includeClasses,
    exclude_classes: excludeClasses,
    source: "commoncrawl",
    min_width: minWidth || 100,  // Ensure width is greater than minWidth
    min_height: minHeight || 0,  // Ensure height is greater than minHeight
    label_quality_estimation: labelQuality || 50  // Ensure label quality is
    // greater than the threshold
  };

  const requestBody = {
    page: page,
    fetch_all: fetchAll,
    query: q
  };

  try {
    const response = await fetch(`${apiUrl}/api/get-labeled-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Update state with image data and pagination details
    setImages(data.output);
    setCurrentPage(data.current_page);
    setTotalPages(data.total_pages);
    setTotalItems(data.length);

    return data.output;  // Return data for CSV conversion
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
};
  // Handle search form submit
  const handleSearch = (e) => {
    e.preventDefault();
    fetchData(1); // Reset to page 1 when performing a new search
  };

  // Function to render bounding boxes
  const renderBoundingBoxes = (objectDetection, imgOriginalWidth, imgOriginalHeight, imgId) => {
    const dimensions = imageDimensions[imgId];
    if (!dimensions) return null;

    const { containerWidth, containerHeight } = dimensions;

    const originalAspectRatio = imgOriginalWidth / imgOriginalHeight;
    const renderedAspectRatio = containerWidth / containerHeight;

    let scaleX, scaleY, offsetX = 0, offsetY = 0;

    if (originalAspectRatio > renderedAspectRatio) {
      scaleX = containerWidth / imgOriginalWidth;
      scaleY = scaleX;
      offsetY = (containerHeight - imgOriginalHeight * scaleY) / 2;
    } else {
      scaleY = containerHeight / imgOriginalHeight;
      scaleX = scaleY;
      offsetX = (containerWidth - imgOriginalWidth * scaleX) / 2;
    }

    return Object.keys(objectDetection).flatMap((classname) =>
      objectDetection[classname].map((coords, i) => {
        const left = Math.round(coords[0] * imgOriginalWidth * scaleX + offsetX);
        const top = Math.round(coords[1] * imgOriginalHeight * scaleY + offsetY);
        const width = Math.round((coords[2] - coords[0]) * imgOriginalWidth * scaleX);
        const height = Math.round((coords[3] - coords[1]) * imgOriginalHeight * scaleY);

        return (
          <div
            key={`${classname}-${i}`}
            className="bounding-box"
            style={{
              left: `${left}px`,
              top: `${top}px`,
              width: `${width}px`,
              height: `${height}px`,
              border: `2px solid ${classColors[classname] || 'black'}`,
            }}
          >
            <span
              className="bounding-label"
              style={{
                backgroundColor: `${classColors[classname] || 'black'}`,
                color: 'black',
                padding: '1px 3px',
                fontSize: '12px',
              }}
            >
              {classname}
            </span>
          </div>
        );
      })
    );
  };


  // Function to download CSV
const downloadCSV = async () => {
  setCsvLoading(true);

  const q = {
    include_classes: includeClasses,
    exclude_classes: excludeClasses,
    source: "commoncrawl",
    min_width: minWidth || 100,  // Ensure width is greater than minWidth
    min_height: minHeight || 0,  // Ensure height is greater than minHeight
    label_quality_estimation: labelQuality || 50  // Ensure label quality is
    // greater than the threshold
  };

  const requestBody = {
    fetch_all: true,
    query: q
  };

  try {
    const response = await fetch(`${apiUrl}/api/get-labeled-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  } finally {
    setCsvLoading(false);
  }
};


  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/user`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserName(data.email);
      } else {
        setIsAuthenticated(false);
        localStorage.clear();
      }
    } catch (error) {
      setIsAuthenticated(false);
      localStorage.clear();
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        localStorage.clear();
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        localStorage.clear();
        setIsAuthenticated(false);
        console.error('Failed to log out');
      }
    } catch (error) {
      localStorage.clear();
      setIsAuthenticated(false);
      console.error('Error during logout:', error);
    }
  };

    const checkSubscriptionStatus = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/check-subscription-status`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      if (data.subscription_active) {
        setSubscriptionActive(true);
        fetchUserData();
      } else {
        redirectToStripeCheckout();
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

        const redirectToStripeCheckout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const session = await response.json();
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error('Stripe Checkout error:', error);
      }
    } catch (error) {
      console.error('Error during Stripe checkout:', error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else{
      checkSubscriptionStatus();
      fetchCustomerId()
    }
  }, [isAuthenticated, navigate, location]);


  // Pagination controls
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchData(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchData(currentPage - 1);
    }
  };

    const handleImageLoad = (event, imgId) => {
    const { naturalWidth, naturalHeight } = event.target;
    const { width: containerWidth, height: containerHeight } = event.target.getBoundingClientRect();

    setImageDimensions((prev) => ({
      ...prev,
      [imgId]: { containerWidth, containerHeight, naturalWidth, naturalHeight },
    }));
  };


  if (!subscriptionActive) {
    return <div>Redirecting to Stripe checkout...</div>;
  }

  return (
        <div className="page-container">
  <div className="content-wrap">
        <div className="cortex-container">
          <div className="cortex-user-info">
            <span className="cortex-username">User: {userName}</span>
            <h1>Cortex: The Biggest AI Dataset</h1>
            <button onClick={handleCreatePortalSession}
                    className="stripe-portal-button">
              Open Stripe Portal
            </button>
            <button onClick={handleLogout} className="logout-button">Logout
            </button>
          </div>

          <form className="cortex-form" onSubmit={handleSearch}>
            <label className="cortex-form-label">
              Modality:
              <select className="cortex-input" value={taskType}
                      onChange={(e) => setTaskType(e.target.value)} disabled>
                <option value="Image">Image
                </option>
              </select>
            </label>

            <label className="cortex-form-label">
              Task:
              <select className="cortex-input" value={taskType}
                      onChange={(e) => setTaskType(e.target.value)} disabled>
                <option value="Object Detection">Object Detection (Pascal VOC
                  classes)
                </option>
              </select>
            </label>

            <label className="cortex-form-label">
              Include Object Classes:
              <Select
                  isMulti
                  options={classOptions}
                  value={classOptions.filter(option => includeClasses.includes(option.value))}
                  onChange={handleIncludeChange}
                  placeholder="Select object classes to include"
                  styles={{
                    valueContainer: (provided) => ({
                      ...provided,
                      maxHeight: '90px',
                      overflowY: 'auto',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
              />
            </label>

            <label className="cortex-form-label">
              Exclude Object Classes:
              <Select
                  isMulti
                  options={classOptions}
                  value={classOptions.filter(option => excludeClasses.includes(option.value))}
                  onChange={handleExcludeChange}
                  placeholder="Select object classes to exclude"
                  styles={{
                    valueContainer: (provided) => ({
                      ...provided,
                      maxHeight: '90px',
                      overflowY: 'auto',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
              />
            </label>

            <label className="cortex-form-label">
              Minimum Width:
              <input
                  className="cortex-input"
                  type="number"
                  value={minWidth}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0) {
                      setMinWidth(value);
                    } else {
                      alert("Please enter a value greater than or equal to 0.");
                    }
                  }}
                  placeholder="e.g., 100"
              />
            </label>

            <label className="cortex-form-label">
              Minimum Height:
              <input
                  className="cortex-input"
                  type="number"
                  value={minHeight}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0) {
                      setMinHeight(value);
                    } else {
                      alert("Please enter a value greater than or equal to 0.");
                    }
                  }}
                  placeholder="e.g., 100"
              />
            </label>

            <label className="cortex-form-label">
              Label Quality (0%-100%):
              <input
                  className="cortex-input"
                  type="number"
                  value={labelQuality}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0 && value <= 100) {
                      setLabelQuality(value);
                    } else {
                      alert("Please enter a value between 0 and 100.");
                    }
                  }}
                  placeholder="e.g., 50"
              />
            </label>

            {/* Button Group for Search and CSV */}
            <div className="button-group">
              <button className="cortex-button" type="submit"
                      onClick={handleSearch}>Search
              </button>
              <button className="cortex-button" onClick={downloadCSV}>Download
                CSV
              </button>
            </div>

          </form>

          {(loading || csvLoading) && (
              <div className="cortex-spinner">
                <div className="spinner"></div>
              </div>
          )}

          {!loading && !searchInitiated && (
              <div className="no-images-message">

                <p>Please click on search to get started!</p>
              </div>
          )}

          {!loading && !csvLoading && searchInitiated && (
              <>
                {images.length > 0 ? (
                    <div className="cortex-gallery">
                      {images.map((imageData) => (
                          <div>
                            <div key={imageData._id}
                                 className="cortex-image-container">
                              <div className="cortex-image-wrapper">
                                <a href={imageData.url} target="_blank"
                                   rel="noopener noreferrer">
                                  <img
                                      onLoad={(event) => handleImageLoad(event, imageData._id)}
                                      src={imageData.url}
                                      className="cortex-image"
                                  />
                                  {imageData.object_detection && renderBoundingBoxes(imageData.object_detection, imageData.width, imageData.height, imageData._id)}
                                </a>
                              </div>

                            </div>
                            <p className="label-quality">
                              Label
                              Quality: {(imageData.label_quality_estimation * 100).toFixed(2)}%
                            </p>
                          </div>
                      ))}
                    </div>
                ) : (
                    <div className="no-images-message">
                      <p>No images found. Try a different query!</p>
                    </div>
                )}
              </>
          )}
          {!loading && totalItems > 0 && (
              <div className="pagination-controls">
                <button
                    className="cortex-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1 || totalPages === 1}  // Disable if on first page or if there's only 1 page
                >
                  Previous
                </button>
                <span
                    className="pagination-info">Page {currentPage} of {totalPages}</span>
                <button
                    className="cortex-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || totalPages === 1}  // Disable if on last page or if there's only 1 page
                >
                  Next
                </button>
              </div>
          )}
        </div>
        </div>

        <footer className="cortex-footer">
          <a
              href="https://www.piculjantechnologies.ai/cortex-platform"
              target="_blank"
              rel="noopener noreferrer"
              className="cortex-footer-link"
          >
            https://www.piculjantechnologies.ai/cortex-platform
          </a>
          <p>Copyright © 2024 Piculjan Technologies LLC</p>
        </footer>

      </div>
  );
};

export default Dashboard;
