import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ForgotPasswordScreen.css';  // Import the updated CSS

const ForgotPasswordScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const [email, setEmail] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(apiUrl + '/api/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        window.alert('Password reset link sent to your email.');
      } else {
        const error = await response.json();
        window.alert(error.message || 'Failed to send password reset link');
      }
    } catch (err) {
      window.alert('An error occurred while sending the reset link');
    }
  };

  return (
    <div className="forgot-password-screen">
      <div className="forgot-password-container">
        <h1>Forgot Password</h1>
        <form onSubmit={handleForgotPassword}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit">Send Reset Link</button>
        </form>

        <p>
          <Link to="/login">Return to Log In</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
