import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './SignupScreen.css';  // New CSS

const SignupScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      window.alert('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(apiUrl + '/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        window.alert('Signup successful! You can now log in.');
        navigate('/login');  // Redirect to login page after successful signup
      } else {
        const error = await response.json();
        window.alert(error.message || 'Signup failed');
      }
    } catch (err) {
      window.alert('An error occurred during signup');
    }
  };

  return (
    <div className="signup-screen">
      <div className="signup-container">
        <h1>Sign Up</h1>
        <form onSubmit={handleSignup}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Sign Up</button>
        </form>

        <p>
          Already have an account? <Link to="/login">Log in here</Link>
        </p>
      </div>
    </div>
  );
};

export default SignupScreen;
