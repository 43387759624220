import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ResetPasswordScreen.css';

const ResetPasswordScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      window.alert('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        window.alert('Password has been reset successfully!');
        navigate('/login');
      } else {
        const error = await response.json();
        window.alert(error.message || 'Password reset failed');
      }
    } catch (err) {
      window.alert('An error occurred during password reset');
    }
  };

  return (
    <div className="reset-password-screen">
      <div className="reset-password-container">
        <h1>Reset Password</h1>
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm New Password</label>
            <input
              id="confirm-password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
