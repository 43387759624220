import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';  // Import Stripe
import LoginScreen from './components/LoginScreen';
import SignupScreen from "./components/SignupScreen";
import ForgotPasswordScreen from "./components/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/ResetPasswordScreen";
import Dashboard from "./components/Dashboard";

const AuthContext = React.createContext();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);  // Your Stripe
// publishable key

console.log('Stripe Publishable Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check if user is authenticated by looking at local storage
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    // Update local storage when isAuthenticated changes
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
        <Elements stripe={stripePromise}>  {/* Wrap the app with Stripe Elements */}
          <div className="App">
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route
                path="/dashboard"
                element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/signup"
                element={<SignupScreen />}
              />
              <Route path="/forgot-password" element={<ForgotPasswordScreen />} /> {/* Add the forgot password route */}
              <Route path="/reset-password/:token" element={<ResetPasswordScreen />} />  {/* Add reset password route */}
            </Routes>
          </div>
        </Elements>
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
}

export { AuthContext };
export default App;
